<template>
  <div class="wrap" :class="tradeMethod">
    <div v-show="false">{{ balance }}{{ limitArr }}</div>
    <van-nav-bar :title="navTitle" left-arrow @click-left="$router.back()" />
    <div class="detailInfo">
      <div class="top_bar">
        <div class="title">
          <span class="text">{{ detailData.title || "" }}</span>
          <i class="iconfont icon-iconfontanquan" style="color: #29c59c"></i>
        </div>
        <i class="iconfont icon-yinhangqia"></i>
      </div>
      <div class="line">
        <span class="label">{{ $t("p2p.coinList.label.price") }}</span>
        <span class="price_value">{{
          $money(detailData.price || "0.00", true, "C$")
        }}</span>
        <!-- <span class="price_value">{{ `${toMoney(1)} ${userInfo.currency_code}` }}</span> -->
      </div>
      <div class="line">
        <span class="label">{{ $t("p2p.coinList.label.amount") }}</span>
        <span class="value">{{
          `${detailData.amount || 0.0} ${coinType}`
        }}</span>
      </div>
      <div class="line">
        <span class="label">{{ $t("p2p.coinList.label.limit") }}</span>
        <span class="value">{{ detailData.limit }}</span>
      </div>
    </div>
    <div class="trade">
      <div class="title">
        {{ $t("p2p.tradeDetails.trade_title_" + tradeMethod, { __: coinType }) }}
      </div>
      <div class="input_wrap">
        <input type="text" v-model="moneyAmount" @input="moneyInput" placeholder="0.00" />
        <div class="right">
          <!-- <div class="unit">{{ userInfo.currency_code || "" }}</div> -->
          <div class="unit">CAD</div>
          <div class="btn_all" @click="handleAll('money')">
            {{ $t("common.all") }}
          </div>
        </div>
      </div>
      <div class="input_wrap">
        <input type="text" v-model="coinAmount" placeholder="0.00" @input="coinInput" />
        <div class="right">
          <div class="unit">{{ coinType || "" }}</div>
          <div class="btn_all" @click="handleAll('coin')">
            {{ $t("common.all") }}
          </div>
        </div>
      </div>
      <!-- <div class="balance">
        {{ `${$t('availableBalance')}: ${$money(balance, false)} ${userInfo.currency_code || "USD"}` }}
      </div> -->
    </div>
    <div class="bottom_bar">
      <div class="content">
        <template v-if="tradeMethod == 'buy'">
          <van-button class="buys" block style="border-radius: 4px" @click="handleSubmit">
            {{
              $t("p2p.tradeDetails.submit_now", {
                __: tradeMethodText,
              })
            }}
          </van-button>
        </template>
        <!-- :color="tradeMethod === 'buy' ? 'var(--btnColor)' : 'var(--trade-red)'" -->
        <template v-else>
          <van-button class="sells" block style="border-radius: 4px" @click="handleSubmit">
            {{
              $t("p2p.tradeDetails.submit_now", {
                __: tradeMethodText,
              })
            }}
          </van-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import tradeMethods from "./tradeMethods.json";
import { NavBar, Button, Toast } from "vant";
import { $post, $get, dealError } from "@/utils";
import { mapGetters } from "vuex";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
  },
  data() {
    return {
      navTitle: "",
      detailData: {},
      coinType: "",
      tradeMethod: "",
      tradeMethodText: "",
      moneyAmount: "",
      coinAmount: "",
      coinId: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo", "unitData", "coins"]),
    coinList() {
      return this.$store.getters.coinList(this.tradeMethod);
    },
    balance() {
      /**
       * 之前错误的换算
       * @type {default.computed.userInfo.money}
       */
      // const unitItem = this.unitData.find(
      //   (item) => item.code === this.userInfo.currency_code
      // );
      // if (unitItem && this.userInfo) {
      //   return (this.userInfo.money / unitItem.rate).toFixed(2);
      // }
      // return "0.00";
      return this.userInfo.money;
    },
    limitArr() {
      const arr = this.detailData?.limit?.split("-")
      if (arr) {
        const lessStr = arr[0];
        const moreStr = arr[1];
        const reg = /\d/g
        const less = lessStr?.match(reg).join("");
        const more = moreStr?.match(reg).join("");
        return [Number(less), Number(more)]
      }
      return []
    }
  },
  mounted() {
    const { type, coinType, id } = this.$route.query;
    const typeText = tradeMethods.find((item) => item.value === type).title;
    this.tradeMethodText = this.$t(typeText);
    this.navTitle = `${this.$t(typeText)} ${coinType}`;
    this.coinType = coinType;
    this.tradeMethod = type;
    this.coinId = id;
    this.getData(id);
  },
  // watch: {
  //   moneyAmount(val) {
  //     if (val) {
  //       console.log(val, this.coinAmount);
  //     }
  //   }
  // },
  methods: {
    async getData(id) {
      try {
        const { ret, msg, data } = await $get("/ptop/lists", { id });
        this.detailData = data;
      } catch (error) {
        dealError(error);
      }
    },
    moneyInput(e) {
      const val = e.target.value;
      this.coinAmount = this.toCoin(val);
    },
    coinInput(e) {
      const val = e.target.value;
      this.moneyAmount = this.toMoney(val);
    },
    // toCoin(val) {
    //   const unitItem = this.unitData.find(item => item.code === this.userInfo.currency_code);
    //   const coinItem = this.coins.find(item => item.value === this.coinType);
    //   if (unitItem && coinItem) {
    //     const usdtAmount = val * unitItem.rate;
    //     const coinAmount = usdtAmount / coinItem.rate;
    //     return this.fixed(coinAmount);
    //   }
    //   return "";
    // },
    toCoin(val) {
      return this.fixed(val / this.detailData.price);
    },
    toMoney(val) {
      return this.fixed(val * this.detailData.price);
    },
    // toMoney(val) {
    //   const unitItem = this.unitData.find(item => item.code === this.userInfo.currency_code);
    //   const coinItem = this.coins.find(item => item.value === this.coinType);
    //   if (unitItem && coinItem) {
    //     const usdtAmount = val / unitItem.rate;
    //     const moneyAmount = usdtAmount * coinItem.rate;
    //     return this.fixed(moneyAmount);
    //   }
    //   return "";
    // },
    handleAll(type) {
      /**
       * 之前错误的换算
       * @type {default.computed.userInfo.money}
       */
      // this.moneyAmount = this.balance;
      // this.coinAmount = this.toCoin(this.balance);
      this.coinAmount = this.balance;
      this.moneyAmount = this.toMoney(this.balance);
      // switch (type) {
      //   case "money":
      //     this.moneyAmount = this.userInfo.money;
      //     break;

      //   default:
      //     break;
      // }
    },
    handleSubmit() {
      const amount = Number(this.coinAmount);
      if (!amount) return;
      if (amount > Number(this.detailData.amount)) {
        return Toast(this.$t("p2p.limitHint.notEnough"));
      }
      if (amount < this.limitArr[0]) {
        return Toast(this.$t("p2p.limitHint.less", { __: this.limitArr[0] }));
      }
      if (amount > this.limitArr[1]) {
        return Toast(this.$t("p2p.limitHint.more", { __: this.limitArr[1] }));
      }
      this.$router.push(
        `/coinOrderDetail?type=${this.tradeMethod}&coinType=${this.coinType}&id=${this.coinId}&amount=${this.coinAmount}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
@comMargin: calc(13rem / 16);
@grey: #999;
@Color: var(--theme);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

.detailInfo {
  margin: calc(20rem / 16) @comMargin 0;
  border-radius: 8px;
  padding: @comMargin;
  // background-color: #fff;
  background-color: @bg;
  // color: #fff;
  font-size: calc(14rem / 16);
  // @grey: rgba(255, 255, 255, .6);

  .top_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >.title {
      font-size: calc(14rem / 16);
      color: @sizeColor;
    }

    >img {
      @size: calc(24rem / 16);
      width: @size;
    }

    >.iconfont {
      font-size: calc(24rem / 16);
      // color: #ffb628;
      color: @Color;
    }
  }

  .line {
    margin-top: calc(6rem / 16);

    .label {
      // color: @grey;
      color: @sizeColor;
      margin-right: calc(8rem / 16);
    }

    .price_value {
      // color: #FF4040;
      color: @Color;
      font-size: calc(16rem / 16);
    }

    .value {
      color: @Color;
    }
  }
}

.trade {
  padding: @comMargin;

  .title {
    font-size: calc(15rem / 16);
  }

  .input_wrap {
    margin-top: calc(16rem / 16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(8rem / 16);
    border-radius: 4px;
    // background-color: #fff;
    background-color: @bg;

    &:nth-child(2) {
      margin-top: calc(8rem / 16);
    }

    input {
      border: none;
      font-size: calc(15rem / 16);
      background: transparent;
      color: @Color;
    }

    input::placeholder {
      color: @sizeColor;
    }

    .right {
      display: flex;
      font-size: calc(14rem / 16);

      .unit {
        @gap: calc(12rem / 16);
        // color: @grey;
        color: @sizeColor;
        padding-right: @gap;
        margin-right: @gap;
        // border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-right: 1px solid @Color;
      }

      .btn_all {
        // color: #01bd8d;
        color: @Color;
      }
    }
  }

  .balance {
    font-size: calc(12rem / 16);
    margin-top: calc(6rem / 16);
    color: @grey;
  }
}

.bottom_bar {
  position: fixed;
  bottom: calc(30rem / 16);
  overflow: hidden;
  width: 100vw;
  max-width: 750px;

  .content {
    margin: @comMargin;
  }
}

.buy {
  .price_value {
    color: var(--trade-green);
  }
}
.buys{
  background-color: var(--btnColor);
  color: var(--btnsizeColor);
  border-color: var(--btnColor);
}
.sells{
  background-color: #fa4055;
  color: #fff;
  background-color: #fa4055;
}
</style>